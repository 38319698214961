<template>
  <div class="customer-table">
    <a-modal
            title="选择商品"
            :visible="visibleTable"
            :confirm-loading="confirmLoading"
            @ok="handleOk"
            @cancel="handleCancel"
            class="customer-table-modal"
            width="73%"
            :maskClosable="false"
            >
            <a-form-model :labelCol="{span: 7}" :wrapperCol="{span:17}">
              <a-row :gutter="4">
                <!-- <a-col :md="6">
                  <a-form-model-item label="物料编号">
                    <a-input v-model.trim="queryParam.goodsCode" placeholder="商品名称" :max-length="50"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :md="6">
                  <a-form-model-item label="编码名称">
                    <a-input v-model.trim="queryParam.cusCodeName" placeholder="请输入经销商编码名称" :max-length="50"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :md="6">
                  <a-form-model-item label="图号">
                    <a-input v-model.trim="queryParam.chartCount" placeholder="请输入图号" :max-length="50"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :md="6">
                  <a-form-model-item label="OE码">
                    <a-input v-model.trim="queryParam.oeCode" placeholder="请输入OE码" :max-length="50"></a-input>
                  </a-form-model-item>
                </a-col> -->
                <a-col :md="6">
                  <a-form-model-item label="商品名称">
                    <a-input v-model.trim="queryParam.name" placeholder="请输入商品名称" :max-length="50"></a-input>
                  </a-form-model-item>
                </a-col>
                <!-- <a-col :md="6">
                  <a-form-model-item label="商品分类">
                    <a-cascader :options="categoryList" v-model="categoryValue" :field-names="{ label: 'name', value: 'id', children: 'children' }" placeholder="请选择" @change="onCategoryChange" />
                  </a-form-model-item>
                </a-col>
                <a-col :md="6">
                  <a-form-model-item label="车信息">
                    <a-cascader :options="options" v-model="carinfo" :field-names="{ label: 'name', value: 'id', children: 'children' }" placeholder="请选择" @change="onCarInfo" />
                  </a-form-model-item>
                </a-col> -->
                <a-col :md="6">
                  <a-form-model-item :labelCol="{span: 0}" :wrapperCol="{span:22,offset:1}">
                    <a-button @click="toSearch(0)" type="primary" icon="search">查询</a-button>
                    <a-button @click="toSearch(1)" type="default" icon="sync">重置</a-button>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model>
            <a-table
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                :columns="columnsChoose"
                :data-source="productList"
                 >
                <span slot="imgUrl" slot-scope="item">
                  <ZoomMedia style="height: 20px;" :value="item"></ZoomMedia>
                </span>
                <span slot="name" slot-scope="text">
                  <a-tooltip> <template slot="title"> {{text}} </template> {{text}} </a-tooltip>
                </span>
            </a-table>
        </a-modal>
  </div>
</template>

<script>
  const qs = require('qs')
  export default {
    data() {
      return {
        columnsChoose: [
          {
              title: 'CNC商号',
              dataIndex: 'cncCompany',
              key: 'cncCompany',
              width: 200,
              fixed: 'left',
              ellipsis:true,
          },
          {
              title: '商品名称',
              dataIndex: 'name',
              key: 'name',
              width: 200,
              fixed: 'left',
              ellipsis:true,
              scopedSlots: {customRender: 'cusName'}
          },
          {
              title: '规格型号',
              align: 'center',
              width: 150,
              ellipsis:true,
              dataIndex: 'specStr',
          },
          {
              title: '总成号',
              align: 'center',
              width: 150,
              ellipsis:true,
              dataIndex: 'assemblyNumber',
          },
          {
              title: '商品图标',
              width: 150,
              dataIndex: 'imgUrl',
              align: 'center',
              ellipsis:true,
              scopedSlots: { customRender: 'imgUrl' },
          },
          // {
          //     title: '分类',
          //     width: 200,
          //     dataIndex: 'categoryName',
          // },
          {
              title: '价格',
              align: 'right',
              width: 200,
              ellipsis:true,
              dataIndex: 'price',
          },
          {
              title: '适用品牌',
              align: 'center',
              width: 150,
              ellipsis:true,
              dataIndex: 'carBrand',
          },
          // {
          //     title: '车系',
          //     align: 'center',
          //     width: 150,
          //     dataIndex: 'carSeries',
          // },
          {
              title: '适用车型',
              align: 'center',
              width: 150,
              ellipsis:true,
              dataIndex: 'carModel',
          },
          // {
          //     title: '排量',
          //     align: 'center',
          //     width: 200,
          //     dataIndex: 'carDisplacement',
          // },
          // {
          //     title: 'OE码',
          //     align: 'center',
          //     width: 150,
          //     dataIndex: 'oeCode',
          // },
          // {
          //     title: '图号',
          //     align: 'center',
          //     width: 150,
          //     dataIndex: 'chartCount',
          // },
        ],
        queryParam: {},
        visibleTable: false,
        confirmLoading: false,
        productList: [],
        selectedRowKeys: [], 
        options: [],
        item: [],
        carinfo: [],
        resetQueryParam: null,
        page: {
          current: 1,
          pageSize: 10,
          total: 0,
        },
        categoryList: [],
        categoryValue: []
      }
    },
    props: {
      cusId: String
    },
    computed: {
      hasSelected() {
        return this.selectedRowKeys.length > 0;
      },
    },
    methods: {
      isShow() {
        if(this.cusId == '') {
          this.$message.warning('请选择经销商')
          return
        }
        this.axios.get('/api/product/car/carBaseInfo/list?flagEnable=true').then(res => {
          this.options = res.body[0] ? res.body[0].children : []
        })
        this.axios.get('/api/product/category/list?flagEnable=true').then(res => {
          this.categoryList = res.body[0] ? res.body[0].children : []
        })
        // this.axios.get(`/api/product/system/goods/goodsList?flagUp=1&cusId=${this.cusId}`).then(res => {
        //   this.productList = res.body.records
        // })
        this.toSearch(1)
        this.visibleTable = true
      },
      toSearch(type) {
        if (type == 1) {
          this.resetQueryParam ? this.queryParam = Object.assign({}, this.resetQueryParam) : this.resetQueryParam =
            Object.assign({}, this.queryParam)
            this.carinfo = []
            this.categoryList = []
        }
        if (type != 2) {
          this.selectedRowKeys = []
          this.selectedRows = []
        }
        this.page.pageNumber = type == 2 ? this.page.current : this.page.current = 1
        const postData = Object.assign({}, this.queryParam, this.page,{cusId:this.cusId,flagUp:1})
        this.axios.get(`/api/product/system/goods/goodsList?${qs.stringify(postData)}`).then(res => {
          // let tmpArr=res.body.records.map(x=>this.becomeGoods(x))
          this.productList = res.body.records
          this.page.total = res.body.total
        }).catch(err => {})
      },
      showModal() {
        this.visibleTable = true;
      },
      handleOk(e) {
        this.$emit('get-pruduct-list', this.item)
        this.visibleTable = false
        this.clearSelection = []
      },
      handleCancel(e) {
        this.visibleTable = false;
      },
      start() {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.selectedRowKeys = [];
        }, 1000);
      },
      onSelectChange(selectedRowKeys, selectedRows) {
        this.selectedRowKeys = selectedRowKeys;
        this.item = selectedRows
      },
      onCarInfo(value) {
        this.queryParam.carBrandId = value ? value[0] : ''
        this.queryParam.carSeriesId = value ? value[1] : ''
        this.queryParam.carModelId = value ? value[2] : ''
      },
      onCategoryChange(value) {
        var reg = new RegExp(",","g")
        this.queryParam.categoryIdStr = value.map(i => {return i}).toString().replace(reg, '_')
      }
    }
  }
</script>

<style>
  .customer-table {
    
  }
</style>